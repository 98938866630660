<template>
    <div class="plan mb-3" v-if="subscription_plan.plan_type !== 'free' || !subscription_bought(subscription_plan)">
      <div
        v-if="subscription_plan.discount_text && !subscription_bought(subscription_plan)"
        class="discount-badge"
      >
            <span
              v-if="subscription_plan.discount_text_color && subscription_plan.discount_text_background_color"
              :style="`color: ${subscription_plan.discount_text_color}; background-color: ${subscription_plan.discount_text_background_color};`"
            >{{ subscription_plan.discount_text }}</span>

        <span
          v-if="subscription_plan.discount_text_color && !subscription_plan.discount_text_background_color"
          :style="`color: ${subscription_plan.discount_text_color};`"
        >{{ subscription_plan.discount_text }}</span>

        <span
          v-if="!subscription_plan.discount_text_color && subscription_plan.discount_text_background_color"
          :style="`background-color: ${subscription_plan.discount_text_background_color};`"
        >{{ subscription_plan.discount_text }}</span>

        <span
          v-if="!subscription_plan.discount_text_color && !subscription_plan.discount_text_background_color"
        >{{ subscription_plan.discount_text }}</span>
      </div>

      <div class="image-wrapper subscription">
        <img
          v-for="market in subscription_plan.markets"
          v-bind:key="market.id"
          :src="require(`@/assets/icon-${market.slug}-big.svg`)"
          :title="market.name"
          :alt="market.name"
        />

        <img
          v-for="product in subscription_plan.products"
          v-bind:key="product.id"
          :src="product.product_image"
          :title="product.name"
          :alt="product.name"
        />
      </div>

      <h5>{{ subscription_plan.name }}</h5>

      <p v-if="!subscription_bought(subscription_plan)">
        {{ subscription_plan.description }}
      </p>

      <div
        class="details-button-wrapper"
        v-if="!subscription_bought(subscription_plan)"
      >
        <a
          v-if="subscription_plan.long_description"
          @click="long_description_popup(subscription_plan, index, 'subscription_plan')"
        >
          {{ $t('profile.payment_and_subscription.show_details') }}
        </a>
      </div>

      <h6
        class="price"
        v-if="!subscription_bought(subscription_plan)"
      >
        {{ subscription_plan.price | currency }}

        <span>
              {{ $t('profile.payment_and_subscription.price_per') }} {{ format_interval_unit(subscription_plan.payment_interval_group) }}
            </span>

        <br>

        <small>
          {{ $t('profile.payment_and_subscription.plus_tax') }}
        </small>

        <br>

        <span
          class="trial"
          v-if="subscription_plan.trial_length > 0 && !subscription_plan.hide_trial_text"
        >
              {{ subscription_plan.trial_length }} {{ subscription_plan.trial_unit }} {{ $t('common.free_trial') }}
            </span>
      </h6>

      <base-button
        :aTag="true"
        :href="subscription_url(subscription_plan)"
        v-if="!subscription_bought(subscription_plan) && (subscription_plan.hosted_page_url || subscription_plan.plan_type === 'free')"
      >
        {{ $t('profile.payment_and_subscription.subscribe') }}
      </base-button>

      <div
        class="validation"
        v-if="subscription_bought(subscription_plan) && subscription_plan.plan_type !== 'free'"
      >
        <i class="fas fa-check bought"></i>

        <p>
          {{ $t('profile.payment_and_subscription.valid_until') }} {{ current_user.subscriptions.find(s => s.recurly_plan.id === subscription_plan.id).valid_until }}
        </p>

        <base-button
          @click.native="cancel_reactivate_subscription_popup(subscription_plan.id)"
          id="cancel-subscription"
          :disabled="current_user.subscriptions.find(s => s.recurly_plan.id === subscription_plan.id).canceled"
        >
          {{ current_user.subscriptions.find(s => s.recurly_plan.id === subscription_plan.id).canceled ? $t('profile.payment_and_subscription.canceled') : $t('profile.payment_and_subscription.cancel_subscription') }}
        </base-button>

        <br><br>

        <base-button
          @click.native="cancel_reactivate_subscription_popup(subscription_plan.id, subscription_type = 'recurly', action_type = 'reactivate')"
          v-if="current_user.subscriptions.find(s => s.recurly_plan.id === subscription_plan.id).canceled"
        >
          {{ $t('profile.payment_and_subscription.reactivate_subscription') }}
        </base-button>
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import BaseButton from './BaseButton.vue'

export default {
  data () {
    return {
      env: {
        environment: process.env.NODE_ENV,
        api_url: process.env.VUE_APP_API_URL
      }
    }
  },
  components: {
    BaseButton
  },
  props: {
    subscription_plan: null
  },
  computed: {
    ...mapState({
      current_user: state => state.profile.account.current_user
    })
  },
  methods: {
    cancel_reactivate_subscription_popup (subscription_id, subscription_type = 'recurly', action_type = 'cancel') {
      this.$parent.cancel_reactivate_subscription_popup(subscription_id, subscription_type, action_type)
    },
    subscription_bought (plan) {
      let bought = false

      if (plan.plan_type === 'free') {
        const user_subscriptions = this.current_user.subscriptions.filter(s => s.subscription_type === 'free')

        user_subscriptions.forEach((user_subscription) => {
          if (plan.id === user_subscription.custom_plan_id) {
            bought = true
          }
        })
      } else {
        const recurly_subscriptions = this.current_user.subscriptions.filter(s => s.subscription_type === 'recurly')

        recurly_subscriptions.forEach((recurly_subscription) => {
          if (plan.id === recurly_subscription.recurly_plan.id) {
            bought = true
          }
        })
      }

      return bought
    },
    format_interval_unit (interval_unit) {
      return interval_unit.substring(0, interval_unit.length - 2)
    },
    subscription_url (subscription_plan) {
      if (subscription_plan.plan_type === 'free') {
        return `${this.env.api_url}/webhooks/free-subscribe/${subscription_plan.id}/${this.current_user.id}`
      }

      return `${subscription_plan.hosted_page_url}?account_code=${this.env.environment}-subscriber-${this.current_user.id}&first_name=${this.current_user.name}&last_name=${this.current_user.surname}&email=${encodeURIComponent(this.current_user.email)}`
    }
  }
}
</script>

<style scoped lang="scss">
.plan {
  .price {
    margin-top: auto;
  }

  .details-button-wrapper {
    margin-bottom: 35px;
  }
}

.trial {
  font-size: 14px;
  font-family: 'Athelas', arial, sans-serif;
  line-height: 20px;
  display: inline-block;
}
</style>
